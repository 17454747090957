.diagonal-container {
  display: flex;
  height: 50vh; /* Full-screen height */
  position: relative;
  background-color: #264873; 
}

.left-section {
  flex: 1;
  /* Blue background */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  width: 100%;
  height: 100%;
}

.text-content {
  text-align: center;
  padding: 20px;
}
.right-section {
  flex: 1;
  position: relative;
  overflow: hidden;
}

/* Centered image styles */
.centered-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%); 
  filter: blur(4px);
  opacity: 0.6; /* Slight opacity for the image */
  position: relative;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .diagonal-container {
    height: auto; /* Allow height to adjust for smaller screens */
    flex-direction: column; /* Stack sections vertically */
  }

  .left-section, .right-section {
    height: 50vh; /* Set a specific height for both sections */
  }

  .centered-image {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* Adjust clip-path for full width */
    filter: blur(2px); /* Reduce blur for smaller screens */
  }

  .text-content {
    padding: 10px; /* Reduce padding for smaller screens */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .diagonal-container {
    height: 50vh; /* Maintain the height */
  }
  

  .centered-image {
    filter: blur(3px); /* Slightly adjust blur for tablet view */
  }
}

@media (min-width: 1025px) {
  .diagonal-container {
    height: 500px; /* Maintain the height for desktop */
  }
}
