/* General styles for all devices */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
  max-height: 80px;
  box-sizing: border-box;
  position: relative; /* Ensure proper positioning */
  z-index: 10;
  margin: 0; 
}

.logo-style {
  max-width: 150px;
  object-fit: contain;
}

.header-link {
  color: #264873;
  text-decoration: none;
  margin: 0 10px;
  padding: 10px;
  font-size: 16px;
}

.bold {
  font-weight: bold;
}

.active-link {
  color: #6d9a30;
}

.header-link:hover {
  color: #6d9a30;
}

/* Hamburger menu styles */
.hamburger {
  display: none; /* Hidden on desktop */
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger span {
  width: 15px;
  height: 3px;
  background-color: #264873;
  transition: all 0.3s;
}

.hamburger.open span:nth-child(1) {
  transform: rotate(45deg) translateY(8px);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg) translateY(-8px);
}

/* Desktop view */
.nav-menu {
  display: flex;
  gap: 15px;
  align-items: center;
}

/* Mobile and tablet view */
@media (max-width: 768px) {
  .hamburger {
    display: flex; /* Show hamburger on tablet/mobile */
  }

  .nav-menu {
    display: none; /* Initially hidden */
    position: fixed;
    top: 0;
    right: -100%; /* Off-screen */
    height: 100vh;
    width: 20% !important;
    background-color: rgba(255, 255, 255, 0.95);
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    transition: right 0.3s ease; /* Smooth slide in/out effect */
    z-index: 1000;
  }

  .nav-menu.nav-active {
    right: 0; /* Slide the menu in when nav-active is added */
    display: flex;
  }
  .nav-menu {
    display: none; /* Hide the menu in tablet view by default */
  }


  .header-link {
    font-size: 18px;
    padding: 12px 20px;
    margin: 10px 0;
    width: 100%;
    box-sizing: border-box;
  }

  .logo-style {
    max-width: 150px;
  }

  .hamburger span {
    width: 20px;
  }
}

/* Mobile view */
@media (max-width: 480px) {
  .nav-menu {
    width: 50%;
    height:50%;
  }

  .header-link {
    font-size: 10px;
    padding: 2px;
  }

  .logo-style {
    max-width: 100px;
  }
}
