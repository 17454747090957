/* Container for the form and image */
.reachout-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.reachoutimage-container {
  width:100%;
  height: 500px;
}

.slider-image-wrapper {
  position: relative;
  height: 100%;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #eef2f7;
}

/* Form and image side by side */
.form-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.form-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-right: 30px;
  max-width: 500px;
  margin-top: 20px;
}

.header-text {
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  color: #264873;
}

.label-text {
  display: block;
  margin-bottom: 15px;
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

.input-field, .textarea-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  box-sizing: border-box;
}

.submit-button {
  padding: 10px 20px;
  background-color: #264873;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #1b365e;
}

/* Image styling */
.image1-container {
  max-width: 500px;
}

.contact-image {
  width: 100%;
  border-radius: 10px;
}

/* Contact details and map in a row */
.details-map-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap; /* To handle smaller screens */
}

.contact-details {
  flex: 1;
  margin-left: 60px;
  font-size: 16px;
  color: #333;
}

.contact-details h3 {
  margin-bottom: 20px;
  color: #264873;
  font-size: 1.5rem;
}

.contact-details p {
  padding: 20px;
  margin-bottom: 15px;
  line-height: 1.5;
}

.address-link {
  color: inherit; /* Use the current text color */
  text-decoration: none; /* Remove underline */
}

.icon {
  color: #6d9a30;
  margin-right: 10px;
  font-size: 1.5rem;
}

.address-link:hover {
  text-decoration: underline; /* Add underline on hover */
  cursor: pointer;
}

.email-link:hover, .phone-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Map container styling */
.map-container {
  flex: 2;
}

.map-container iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

.overlay-text-container {
  position: absolute;
  top: 30%;
  right: 25%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Higher than the overlay */
  color: #264873;
  font-size: 30px;
  font-weight: bold;
  text-align: right;
}

.image-container {
  position: relative;
  max-width: 100%;
  margin-bottom: 100px;
}

.contact-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Black blur overlay */
.image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Slight black overlay */
  backdrop-filter: blur(5px); /* Adds a subtle blur effect */
}

/* Media Queries for Responsive Design */

/* For tablets (768px and above) */
@media (max-width: 768px) {
  .reachoutimage-container {
    height: 400px;
  }
  .form-image-container {
    flex-direction: column; /* Stack form and image */
    margin-bottom: 40px;
  }

  .form-container {
    max-width: 100%; /* Expand form width */
    margin-right: 0;
  }

  .contact-details {
    margin-left: 20px;
    font-size: 14px;
  }

  .map-container iframe {
    height: 350px;
  }
}

/* For mobile phones (480px and below) */
@media (max-width: 480px) {
  .reachoutimage-container {
    height: 300px;
  }
  .overlay-text-container {
    font-size: 0.8rem;
    top: 50%;
    right: 10%;
  }
    .header-text {
      font-size: 1.5rem;
    }
  
    .form-image-container {
      flex-direction: column;
    }
  
    .form-container {
      margin-right: 0;
      padding: 15px;
    }
  
    .contact-details {
      margin-left: 10px;
      font-size: 14px;
    }
  
    .contact-details h3 {
      font-size: 1.2rem;
    }
  
    .submit-button {
      font-size: 14px;
      padding: 8px 15px;
    }
  
    .map-container iframe {
      height: 250px;
    }
  
    /* Make the map appear at the top and contact details below */
    .details-map-container {
      flex-direction: column-reverse; /* Reverses the order */
    }
  
    .contact-details {
      order: 2;
    }
  
    .map-container {
      order: 1;
      margin-bottom: 20px;
    }
}
.footer-container {
  background-color: #264873;
  color: white;
  padding: 20px 0;
  margin: 0 auto;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid layout */
  gap: 20px; /* Equal spacing between sections */
  padding: 30px;
  max-width: 1400px; /* Increased width for larger screens */
  
}

.footer-section {
  padding: 10px;
}

.footer-container {
  background-color: #264873;
  color: white;
  padding: 20px 0;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid layout */
  gap: 20px; /* Space between sections */
  padding: 20px;
  max-width: 1200px; /* Constrain width for desktop views */
  margin: 0 auto;
}

.footer-section {
  padding: 10px;
  text-align: left;
}

.footer-section h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 14px;
  line-height: 1.6;
  color: white;
  margin: 0;
}

.footer-section p a {
  color: white;
  text-decoration: none;
}

.footer-section p a:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icons a {
  color: white;
  font-size: 18px;
  text-decoration: none;
}

.social-icons a:hover {
  text-decoration: underline;
}

.footer-bottom {
  background-color: #ffffff;
  padding: 10px 0;
  color: black;
  text-align: center;
  font-weight: bold;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}

/* Responsive Design */

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .footer-section h3 {
    font-size: 16px;
  }
  .footer-section p {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  .footer-content {
    grid-template-columns: repeat(4, 1fr); /* Ensure 4 equal columns */
  }

  .footer-section {
    text-align: left;
  }

  .footer-section h3 {
    font-size: 1.2rem; /* Slightly larger font size for headings */
  }

  .footer-section p {
    font-size: 1rem; /* Slightly larger text for better readability */
  }
}


@media (min-width: 1024px) {
  .footer-content {
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns on larger screens */
    gap: 50px; /* More space between sections */
  }

  .footer-section {
    text-align: left;
  }
}
