/* Main container */
.experience-section {
  width: 100%;
  padding: 40px 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column; /* Default column layout */
  gap: 40px;
  margin-top: 50px;
  overflow-x: hidden;
}

/* Stats section container */
.stats-section {
  display: flex;
  flex-direction: column;
  gap: 40px;

}

/* Each stat-section */
.stat-section1,
.stat-section2,
.stat-section3,
.stat-section4 {
  display: flex;
  align-items: center;
  gap: 5px;
  
}

/* Reverse layout for alternating rows */
.stat-section1,
.stat-section3 {
  margin-left: 150px;
}

.stat-section2,
.stat-section4 {
  flex-direction: row-reverse;
}

/* Images styling */
.stat-icon {
  max-width: 50px;
  border-radius: 10px;
}

/* Text content styling */
.stat-text {
  text-align: left;
}

.stat-text h2 {
  color: #264873;
  margin-bottom: 10px;
}

.stat-text p {
  color: #6c757d;
  line-height: 1.8;
}

/* Text section for summary */
.text-section {
  text-align: left;
}

.text-section h2 {
  color: #264873;
  margin-bottom: 20px;
}

.text-section p {
  color: #6c757d;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: justify;
}

.contact-button {
  color: #fff;
  background-color: #6d9a30;
  padding: 10px 20px;
  border: none;
  font-size:1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-button:hover {
  background-color: #1e3a57;
}

/* Desktop and Laptop: Zigzag pattern */
@media (min-width: 1024px) {
  .experience-section {
    flex-direction: row; /* Arrange sections side by side */
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    overflow-x: hidden;
  }

  .stats-section {
    width: 40%; /* Stats on the left */
    margin-right: 20px; /* Spacing between sections */
  }

  .text-section {
    width: 50%; /* Text on the right */
    text-align: justify;
    margin-right:30px;
  }

  /* Maintain zigzag pattern for stats rows */
  .stat-section1,
  .stat-section3 {
    flex-direction: row;
  }

  .stat-section2,
  .stat-section4 {
    flex-direction: row-reverse;
  }

  .stat-section1,
  .stat-section2,
  .stat-section3,
  .stat-section4 {
    gap: 10px; /* Proper spacing */
  }
}

/* Responsive design adjustments */
/* Mobile */
@media (max-width: 768px) {
  .experience-section {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start;
    gap: 40px; /* Spacing between sections */
    margin:10px;
  }
  .text-section h2 {
    font-size: 1.5rem;
  }

  .stat-section1,
  .stat-section2,
  .stat-section3,
  .stat-section4 {
    position: static; /* Remove absolute positioning */
    flex-direction: column; /* Stack stat items vertically */
    align-items: center; /* Center items */
    gap: 20px; /* Space between items */
    margin:10px 120px;
  }

  .stat-item {
    flex-direction: column; /* Icon above text */
    text-align: center; /* Center text */
  }

  .stat-icon {
    align-items: center;
    margin-bottom: 10px; /* Space below icon */
  }
  .text-section {
    order: 1; /* Text section first */
    width: 100%;
    text-align: center;
  }

  .stats-section {
    order: 2; /* Stats section below */
    width: 100%;
    margin-top: 20px; /* Add spacing below text */
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  .experience-section {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center elements */
  }

  .stat-section1,
  .stat-section2,
  .stat-section3,
  .stat-section4 {
    position: static; /* Remove absolute positioning */
    flex-direction: row; /* Keep items in a row */
    justify-content: space-around; /* Space out items */
  }

  .stat-item {
    flex-direction: row; /* Keep icon on the right */
  }
  .text-section {
    order: 1; /* Text section first */
    width: 100%;
    text-align: center;
  }

  .stats-section {
    order: 2; /* Stats section below */
    width: 100%;
    margin-top: 20px; /* Add spacing below text */
  }
}
