/* OurReputation.css */
.reputation-container {
  text-align: center;
  padding: 50px 20px;
}

.reputation-container h2 {
  margin-bottom: 30px;
  font-weight: bold;
  
}

/* Cards container */
.reputation-cards {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

/* Individual card styles */
.reputation-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.reputation-card:hover {
  color: #4a4389;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.reputation-card .icon {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #6d9a30;
}

.reputation-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

.reputation-card p {
  color: #777;
}
@media (max-width: 768px) {
  .reputation-cards {
    justify-content: space-around;
    gap: 20px;
  }

  .reputation-card {
    max-width: 200px;
    padding: 15px;
  }

  .reputation-card .icon {
    font-size: 1.8rem;
  }

  .reputation-card h3 {
    font-size: 1.1rem;
  }
}

/* Responsive Styles */

/* Tablet devices (768px and below) */
@media (max-width: 768px) {
  .reputation-cards {
    justify-content: space-around;
    gap: 20px;
  }

  .reputation-card {
    max-width: 200px;
    padding: 15px;
  }


  .reputation-card .icon {
    font-size: 1.8rem;
  }

  .reputation-card h3 {
    font-size: 1.1rem;
  }
}

/* Mobile devices (480px and below) */
@media (max-width: 480px) {
  .reputation-cards {
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .reputation-card {
    max-width: 90%;
    padding: 10px;
  }

  .reputation-card .icon {
    font-size: 1.5rem;
  }

  .reputation-card h3 {
    font-size: 1rem;
  }
}
