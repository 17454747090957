

body {
  font-family: 'Arial', sans-serif;
  background-color: #ffffff;
  color: #333;
  line-height: 1.6;
  overflow-x: hidden; /* Prevent horizontal scrolling */

  
}

/* Default for desktop/laptop */
body {
  font-size: 16px;
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
  color: #264873;
}
p {
  font-size: 16px;
}

/* Tablet */
@media (max-width: 768px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
    color: #264873;
  }
  p {
    font-size: 14px;
    
  }
}

/* Mobile */
@media (max-width: 480px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
    color: #264873;
  }
  p {
    font-size: 1rem;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

