.footer-container {
  background-color: #264873;
  color: white;
  padding: 20px 0;
  text-align: left;
}

.footer-content {
  display: flex;
  flex-wrap: wrap; /* Ensures responsiveness */
  justify-content: space-around;
  gap: 20px; /* Equal spacing between sections */
  padding: 20px;
  max-width: 1200px; /* Increase the width for larger screens */
  margin: 0 auto;
}

.footer-section p {
  font-size: 14px;
  color: white; 
  text-decoration: none; /* Ensure no underline by default */
}

.footer-section p a,
.footer-section p a:visited {
  color: white; /* Ensures both unvisited and visited links are white */
  text-decoration: none; /* Removes underline for normal state */
}

.footer-section p a:hover,
.footer-section p a:focus,
.footer-section p a:active {
  text-decoration: underline; /* Underline on hover, focus, or active state */
  color: white; /* Ensure the color remains white on hover and active */
}


.footer-section {
  flex: 1;
  min-width: 200px; /* Ensures sections don’t shrink too much on smaller screens */
  padding: 10px;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
  gap: 20px;
}

.social-icons a {
  color: white;
  margin: 0 10px;
  font-size: 1rem;
  text-decoration: none;
}

.social-icons a:hover {
  text-decoration: underline; /* Underline social icons on hover */
}

.footer-bottom {
  background-color: #ffffff;
  padding: 10px 0;
  color: black;
  text-align: center;
  font-weight: bold;
}

.footer-bottom p {
  margin: 0;
  font-size: 1.5rem;
}

/* Make the footer responsive */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack sections vertically on smaller screens */
    align-items: center;
  }

  .footer-section {
    text-align: center;
  }
}

/* Link styles for email, phone, address, and WhatsApp */
.email-link, .phone-link, .whatsapp-link, .address-link {
  color: inherit; /* Use the current text color */
  text-decoration: none; /* Remove underline */
}

.email-link:hover, .phone-link:hover, .whatsapp-link:hover, .address-link:hover {
  text-decoration: underline; /* Add underline on hover */
  cursor: pointer; /* Show pointer on hover */
}
/* Footer */
footer {
  background-color: #264873;
  color: white;
  padding: 20px;
  text-align: center;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
/* Footer */
footer {
  background-color: #264873;
  color: white;
  padding: 20px;
  text-align: center;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
.footer-section h3 {
  font-size: clamp(16px, 2vw, 18px); /* Dynamically scale heading size */
  margin-bottom: 10px;
}

.footer-section p {
  font-size: clamp(12px, 1.5vw, 14px); /* Dynamically scale paragraph size */
  color: white;
  margin: 0;
}

.footer-section p a,
.footer-section p a:visited {
  color: white;
  text-decoration: none;
}

.footer-section p a:hover,
.footer-section p a:focus,
.footer-section p a:active {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  gap: 10px; /* Space between icons */
  justify-content: center;
}

.social-icons a {
  color: white;
  font-size: 1.2rem;
  text-decoration: none;
}

.social-icons a:hover {
  text-decoration: underline;
}

.footer-bottom {
  background-color: #ffffff;
  padding: 10px 0;
  color: black;
  text-align: center;
  font-weight: bold;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr; /* Stack sections vertically */
    text-align: center;
  }
  .footer-section h3 {
    font-size: 16px; /* Smaller heading size for mobile */
  }
  .footer-section p {
    font-size: 12px; /* Smaller paragraph size for mobile */
  }
}
